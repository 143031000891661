import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Asegúrate de tener Bootstrap

function NegocioNotificaciones({ id }) {
    // Estados para los campos del formulario
    const [nota, setNota] = useState('');
    const [estatus, setEstatus] = useState('');
    const [notificacion, setNotificacion] = useState(false);
    const [fecha, setFecha] = useState('');
    const [hora, setHora] = useState('');
    const [recurrente, setRecurrente] = useState(false);
    const [frecuencia, setFrecuencia] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);


    // Estados para los datos de los combobox
    const [estatusOptions, setEstatusOptions] = useState([]);
    const [frecuenciaOptions, setFrecuenciaOptions] = useState([]);

    // Cargar datos para el combobox de estatus
    useEffect(() => {
        fetch('https://admin.chetito.shop/api/api_negocio_estatus.php')
            .then(response => response.json())
            .then(data => setEstatusOptions(data))
            .catch(error => console.error("Error al cargar estatus:", error));
    }, []);

    // Cargar datos para el combobox de frecuencia
    useEffect(() => {
        fetch('https://admin.chetito.shop/api/api_frecuencia.php')
            .then(response => response.json())
            .then(data => setFrecuenciaOptions(data))
            .catch(error => console.error("Error al cargar frecuencias:", error));
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isSubmitting) return; // Previene llamadas duplicadas
        setIsSubmitting(true); // Bloquea nuevas llamadas
    
        // Construir el cuerpo de la solicitud
        const formData = {
            id,
            nota,
            estatus,
            notificacion, // Asegúrate de que la API espera booleanos o ajusta según necesites
            fecha: notificacion ? fecha : null, // Solo envío fecha y hora si notificacion es true
            hora: notificacion ? hora : null,
            recurrente: notificacion ? recurrente : null, // Similar para recurrente
            frecuencia: notificacion ? frecuencia : null,
        };
    
        // Opciones para la solicitud fetch

        


        const fetchOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        };

        
        // Realizar la solicitud al API
        fetch('https://admin.chetito.shop/api/api_negocio_nuevanota.php', fetchOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Mostrar la respuesta con un alert
                alert(data.message);


            })
            .catch(error => {
                console.error("Error al enviar el formulario:", error);
                alert("Error al enviar el formulario: " + error.message);
            });
    };
    

    return (
        <div>
            <h5>Notificaciones del Negocio</h5>
            <form onSubmit={handleSubmit} className="p-3 border">
                <div className="mb-3">
                    <label htmlFor="nota" className="form-label">Nota</label>
                    <input
                        type="text"
                        className="form-control"
                        id="nota"
                        value={nota}
                        onChange={(e) => setNota(e.target.value)}
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="estatus" className="form-label">Estatus</label>
                    <select
                        className="form-select"
                        id="estatus"
                        value={estatus}
                        onChange={(e) => setEstatus(e.target.value)}
                    >
                        <option key={0} value={0}>Seleccione una Opción</option>

                        {estatusOptions.map((option, index) => (
                            <option key={index} value={option.ID}>{option.Estatus}</option>
                        ))}
                    </select>
                </div>

                <div className="mb-3 form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="notificacion"
                        checked={notificacion}
                        onChange={(e) => setNotificacion(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="notificacion">Notificación</label>
                </div>

                {notificacion && (
                    <>
                        <div className="mb-3">
                            <label htmlFor="fecha" className="form-label">Fecha</label>
                            <input
                                type="date"
                                className="form-control"
                                id="fecha"
                                value={fecha}
                                onChange={(e) => setFecha(e.target.value)}
                            />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="hora" className="form-label">Hora</label>
                            <input
                                type="time"
                                className="form-control"
                                id="hora"
                                value={hora}
                                onChange={(e) => setHora(e.target.value)}
                            />
                        </div>

                        <div className="mb-3 form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="recurrente"
                                checked={recurrente}
                                onChange={(e) => setRecurrente(e.target.checked)}
                            />
                            <label className="form-check-label" htmlFor="recurrente">Recuerrente</label>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="frecuencia" className="form-label">Frecuencia</label>
                            <select
                                className="form-select"
                                id="frecuencia"
                                value={frecuencia}
                                onChange={(e) => setFrecuencia(e.target.value)}
                            >
                                <option key={0} value={0}>Seleccione una Opción</option>
                                {frecuenciaOptions.map((option, index) => (
                                    <option key={index} value={option.id}>{option.TipoFrecuencia}</option>
                                ))}
                            </select>
                        </div>
                    </>
                )}

                <button type="submit" className="btn btn-primary">Enviar</button>
            </form>
        </div>
    );
}

export default NegocioNotificaciones;
