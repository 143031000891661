import React from 'react';
import NegocioNuevo from './NegocioNuevo'; // Asegúrate de que la ruta de importación sea correcta
import NegociosList from './NegociosList';

function Negocios() {
  return (
    <div className="container mt-5">
    <h5>Negocios</h5>
    <div className="row">
        <div >
            <NegocioNuevo />

            <NegociosList />

            
        </div>
    </div>
</div>
  );
}

export default Negocios;
