import React, { useState } from 'react';

function NegocioNuevo() {
    const [negocio, setNegocio] = useState('');
    const [respuesta, setRespuesta] = useState('');
    const [cargando, setCargando] = useState(false);

    const enviarNegocio = async (e) => {
        e.preventDefault();
        setCargando(true);
        setRespuesta('');

        // Validación para no enviar en blanco
        if (!negocio.trim()) {
            alert('Por favor, ingresa el nombre del negocio.');
            setCargando(false);
            return;
        }

        // Validación para asegurar que el negocio tenga más de 3 letras
        if (negocio.trim().length <= 3) {
            alert('El nombre del negocio debe contener más de tres letras.');
            setCargando(false);
            return;
        }

        const datosParaEnviar = { negocio: negocio.trim() };
        console.log('Enviando a la API:', datosParaEnviar); // Esto imprimirá en la consola lo que se envía

        try {
            const response = await fetch('https://admin.chetito.shop/api/api_negocio_nuevo.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(datosParaEnviar),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setRespuesta(data.message);

            // Resetear el formulario después de un éxito
            setNegocio('');
            setTimeout(() => {
                setRespuesta('');
            }, 5000); // Limpiar mensaje después de 5 segundos
        } catch (error) {
            console.error('Error al enviar el negocio:', error);
            setRespuesta('Error al conectar con la API. Por favor, intenta de nuevo.');
        } finally {
            setCargando(false);
        }
    };

    return (
<div className="container mt-5">
    <h5 className="text-center mb-4">Registrar Nuevo Negocio</h5>
    <form onSubmit={enviarNegocio} className="card card-body">
        <div className="mb-3">
            <label htmlFor="nombreNegocio" className="form-label">
                Nombre del Negocio:
            </label>
            <input
                type="text"
                className="form-control"
                id="nombreNegocio"
                value={negocio}
                onChange={e => setNegocio(e.target.value)}
            />
        </div>
        <button type="submit" className={`btn ${cargando ? 'btn-secondary' : 'btn-primary'}`} disabled={cargando}>
            {cargando ? 'Enviando...' : 'Nuevo Negocio'}
        </button>
        {respuesta && <p className="mt-3">{respuesta}</p>}
    </form>
</div>

    );
}

export default NegocioNuevo;
