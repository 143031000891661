import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavigationBar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Home from './components/Home';
import Negocios from './components/Negocios';
import Proyectos from './components/Proyectos';
import Trabajo from './components/Trabajo';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import NegociosList from './components/NegociosList';
import NegocioDetalle from './components/NegocioDetalle';

import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* Definir rutas protegidas utilizando ProtectedRoute y Layout */}
        <Route element={<ProtectedRoute />}>
          <Route path="/home" element={<><Layout><Home /></Layout></>} />
          <Route path="/negocios" element={<><Layout><Negocios /></Layout></>} />
          <Route path="/proyectos" element={<><Layout><Proyectos /></Layout></>} />
          <Route path="/trabajo" element={<><Layout><Trabajo /></Layout></>} />
          <Route path="/negocios-list" element={<><Layout><NegociosList /></Layout></>} />
          <Route path="/negocio/:id" element={<><Layout><NegocioDetalle /></Layout></>} />
        </Route>
      </Routes>
    </Router>
  );
}

// Componente Layout para reutilizar en todas las rutas protegidas
function Layout({ children }) {
  return (
    <>
      <NavigationBar />
      <div style={{ display: 'flex' }}>
        <Sidebar />
        {children}
      </div>
    </>
  );
}

export default App;
