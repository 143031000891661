import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <Nav className="flex-column bg-dark vh-100 text-white">
      <Nav.Link as={Link} to="/home" className="text-white">Home</Nav.Link>
      <Nav.Link as={Link} to="/negocios" className="text-white">Negocios</Nav.Link>
      <Nav.Link as={Link} to="/proyectos" className="text-white">Proyectos</Nav.Link>
      <Nav.Link as={Link} to="/trabajo" className="text-white">Trabajo</Nav.Link>
    </Nav>
  );
};

export default Sidebar;
