
import { Modal, Button, Form } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "./estilos.css";

function NegociosList() {
    const [negocios, setNegocios] = useState([]);
    const [cargando, setCargando] = useState(true); 
    const [showModal, setShowModal] = useState(false);
    const [negocioSeleccionado, setNegocioSeleccionado] = useState({ idNegocio: null, nombre: '' });
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [idNegocioAEliminar, setIdNegocioAEliminar] = useState(null);
    const navigate = useNavigate(); // Usar useNavigate aquí
    
    const cargarNegocios = async () => {
        setCargando(true);

        try {
            const respuesta = await fetch('https://admin.chetito.shop/api/api_negocio_list.php');
            const datos = await respuesta.json();
            setNegocios(datos);
        } catch (error) {
            console.error('Error al cargar los negocios:', error);
        }

        setCargando(false);
    };

    useEffect(() => {
        cargarNegocios();
    }, []);



    // Función para actualizar un negocio
    const actualizarNegocio = (idNegocio, nombre) => {
        setNegocioSeleccionado({ idNegocio, nombre });
        setShowModal(true);
    };


    const handleSubmitActualizar = async (e) => {
        e.preventDefault();
        const nuevoNombre = e.target.nombre.value; // Asegúrate de que el campo del formulario tenga este nombre
    
        fetch('https://admin.chetito.shop/api/api_negocio_actualizar.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `idNegocio=${negocioSeleccionado.idNegocio}&negocio=${nuevoNombre}`,
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            alert('Nombre Actualizado');
            setShowModal(false);
            cargarNegocios(); // Recargar la lista de negocios para mostrar el cambio
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };
    

    const eliminarNegocio = (idNegocio) => {
        setIdNegocioAEliminar(idNegocio);
        setShowConfirmModal(true);
    };
    
    const confirmarEliminacion = () => {
        fetch('https://admin.chetito.shop/api/api_negocio_eliminar.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `idNegocio=${idNegocioAEliminar}`,
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            alert(data.mensaje); // Muestra el mensaje de éxito o error de la API
            setShowConfirmModal(false); // Cierra el modal de confirmación
            cargarNegocios(); // Recarga la lista de negocios para reflejar los cambios
        })
        .catch(error => {
            console.error('Error:', error);
            alert('Error al eliminar el negocio');
        });
    };
    
    
    const irNegocio = (idNegocio) => {
        console.log('Ir negocio', idNegocio);
        navigate(`/negocio/${idNegocio}`); // Usar navigate para ir al detalle del negocio
    };

    return (
        <div className="container mt-4">
            <button className="btn btn-primary mb-3" onClick={cargarNegocios}>Actualizar Lista</button>
            {cargando ? (
                <div className="text-center">
                    <img
                    src={`${process.env.PUBLIC_URL}/cargando.png`}
                    alt="Cargando"
                    style={{ animation: "spin 2s linear infinite", height: "50px" }}
                    />
                </div>
            ) : (
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Negocio</th>
                            <th>Estatus</th>
                            <th>Actualizar</th>
                            <th>Eliminar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {negocios.map((negocio) => (
                            <tr key={negocio.idNegocio}>
                                
                                <td><button className="btn btn-dark mr-2" onClick={() => irNegocio(negocio.idNegocio)} style={{ width: '100%', textAlign: 'left' }}>{negocio.negocio}</button></td>
                                <td>{negocio.estatus}</td>
                                <td>
                                    <button className="btn btn-secondary mr-2" onClick={() => actualizarNegocio(negocio.idNegocio)}>Actualizar</button>
                                </td>
                                <td>
                                    <button className="btn btn-danger" onClick={() => eliminarNegocio(negocio.idNegocio)}>Eliminar</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Actualizar Negocio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitActualizar}>
                        <Form.Group>
                            <Form.Label>Nombre del Negocio</Form.Label>
                            <Form.Control
                                type="text"
                                name="nombre"
                                defaultValue={negocioSeleccionado.nombre}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Actualizar
                        </Button>
                        <Button variant="secondary" onClick={() => setShowModal(false)} style={{ marginLeft: '10px' }}>
                            Cancelar
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Eliminación</Modal.Title>
                </Modal.Header>
                <Modal.Body>¿Estás seguro de que deseas eliminar este negocio?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={() => confirmarEliminacion()}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>

        
    );
}

export default NegociosList;
