import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import NegocioNotificaciones from './NegocioNotificaciones';
import NegocioBisnes from './NegocioBisnes';

function NegocioDetalle() {
    const { id } = useParams();
    const navigate = useNavigate();

    // Aquí puedes cargar los detalles del negocio usando el ID si es necesario

    return (
        <div className="container mt-3">
            <button className="btn btn-primary mb-3" onClick={() => navigate(-1)}>Regresar a la lista de negocios</button>
            <h2>Detalles del Negocio {id}</h2>
            <div className="row">
                <div className="col-md-6">
                    <NegocioNotificaciones id={id} />
                </div>
                <div className="col-md-6">
                    <NegocioBisnes id={id} />
                </div>
            </div>
        </div>
    );
}

export default NegocioDetalle;
