import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Login() {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    // Realizar la solicitud POST a tu API
    fetch('https://admin.chetito.shop/api/api_login.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        password: password,
      }),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        // Si la API indica que la contraseña es correcta, redirige al dashboard
        localStorage.setItem('isAuthenticated', 'true');
        navigate('/home');
      } else {
        // Si la contraseña es incorrecta según la API, muestra una alerta
        alert('Contraseña incorrecta');
      }
    })
    .catch(error => {
      console.error('Error:', error);
      alert('Ocurrió un error al intentar iniciar sesión');
    });
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="w-100" style={{maxWidth: '400px'}}>
        <form onSubmit={handleLogin} className="p-4 shadow rounded">
          <div className="mb-3">
            <label htmlFor="password" className="form-label">Contraseña:</label>
            <input
              type="password"
              id="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary w-100">Iniciar Sesión</button>
        </form>
      </div>
    </div>
  );
  

}

export default Login;
