// NegocioBisnes.js
import React from 'react';

function NegocioBisnes({ id }) {
    return (
        <div>
            <h3>Más sobre el Negocio {id}</h3>
            {/* Agrega aquí más lógica o UI relacionado con el negocio */}
        </div>
    );
}

export default NegocioBisnes;
